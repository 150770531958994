import React from 'react';
import { Container, Stack, Row, Col } from "react-bootstrap";
import './styles/internal-pages.css';
import './styles/index.scss';
import Seo from '../components/seo/seo';
import { graphql } from 'gatsby';

import { UseTheme } from '../context/ThemeContext';
import HubspotFormGeneral from '../components/hubspot-forms/hubspot-form-general';

const useAddCollection = ({ data }) => {

    const { theme } = UseTheme()

    return (
        <div className="background-theme">
            <Container className="pt-5 pb-5 internal-pages-common what-is">
                <h1>{data.allDatoCmsAddCollectionPage.nodes[0].slugPage.title}</h1>
                <Stack className="card-long">
                    <img alt="card-long-first" src={data.allDatoCmsWhatIsDtcPage.nodes[0].bannerPage.url} />
                    <div className='back-image'>
                        <h4>Authentic Digital Trading Cards</h4>
                        <p>Creation / Minting / Marketing Custom Curated Collectibles</p>
                    </div>
                </Stack>
                <Stack>
                    <Row>
                        <Col md={4} className="text-center change-img-order">
                            <img className="img-fluid mt-4 mb-4" alt="card-long-first" src={data.allDatoCmsAddCollectionPage.nodes[0].image.url} />
                        </Col>
                        <Col md={8}>
                            <div className='text-title-img left'>
                                <div>
                                    <h3>
                                        {data.allDatoCmsAddCollectionPage.nodes[0].titleAndParagraph[0].title}
                                    </h3>
                                    <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsAddCollectionPage.nodes[0].titleAndParagraph[0].paragraph }}>
                                    </p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <h4 className='mt-4'>
                            {data.allDatoCmsAddCollectionPage.nodes[0].titleList}
                        </h4>
                        <ul>
                        </ul>
                        {
                            data.allDatoCmsAddCollectionPage.nodes[0].itemsList.map((itemsList, index) =>
                                <li key={index}>{itemsList.label}</li>
                            )
                        }
                        <div className='mt-3'>
                            <p>
                                {data.allDatoCmsAddCollectionPage.nodes[0].messageForm}
                            </p>
                        </div>
                    </div>
                </Stack>
                <Stack className='form-add-collection'>
                    <HubspotFormGeneral targetId='primary' portalId='23479554' formId={theme === 'light' ? 'e378ae02-4a10-42de-9c13-37d4cff8542c' : '9b2dee6c-ede3-4bbf-a379-b8573d7ce76b'} />
                </Stack>
            </Container>
        </div>
    )
}

export default useAddCollection;

export const Head = ({ data }) => (
    <Seo
        title="Add Collection"
        description={data.allDatoCmsAddCollectionPage.nodes[0].seo.description}
        image={data.allDatoCmsAddCollectionPage.nodes[0].seo.image.url}
    />
)

export const query = graphql`
    query AddCollectionQuery {
        allDatoCmsWhatIsDtcPage {
            nodes {
                bannerPage {
                    url
                  }
           }
        }
        allDatoCmsAddCollectionPage {
            nodes {
            seo {
                title
                description
                image {
                url
                }
            }
            slugPage {
                title
                slug
            }
            image {
                url
            }
            titleAndParagraph {
                title
                paragraph
            }
            titleList
            itemsList {
                label
            }
            messageForm
            }
        }
    }
` 